@alert-border-radius: @border-radius;

.alert {
    border-width: 1px;
    border-style: solid;
    padding: @spacer-2 @spacer-4;
    border-radius: @border-radius;
}

.alert-success {
    background-color: #e0fffa;
    border-color: #7be0d0;
    color: #00ab90;
}

@bg-light: @color-white;
@bg-soft: @color-gray-9;
@bg-softer: @color-gray-8;
@bg-softest: @color-gray-7;
@bg-muted: @color-gray-6;
@bg-dark: @color-gray-4;
@bg-darker: @color-gray-3;
@bg-black: @color-gray-2;
@bg-primary: @color-brand-primary;

.bg-light { background-color: @bg-light; }
.bg-soft { background-color: @bg-soft; }
.bg-softer { background-color: @bg-softer; }
.bg-softest { background-color: @bg-softest; }
.bg-muted { background-color: @bg-muted; }
.bg-dark { background-color: @bg-dark; }
.bg-darker { background-color: @bg-darker; }
.bg-black { background-color: @bg-black; }
.bg-primary { background-color: @bg-primary; }
.bg-primary-dark { background-color: darken(@bg-primary, 20%); }
.bg-primary-soft { background-color: fade(@bg-primary, 35%); }
.bg-overlay-soft { background-color: rgba(0,0,0,0.5); }
.bg-overlay-dark { background-color: rgba(0,0,0,0.75); }

.\:bg-light:hover { .bg-light; }

.responsive({
    &bg-light { .bg-light; }
    &bg-soft { .bg-soft; }
    &bg-softer { .bg-softer; }
    &bg-softest { .bg-softest; }
    &bg-muted { .bg-muted; }
    &bg-dark { .bg-dark; }
    &bg-darker { .bg-darker; }
    &bg-black { .bg-black; }
    &bg-primary { .bg-primary; }
    &bg-primary-dark { .bg-primary-dark; }
    &bg-primary-soft { .bg-primary-soft; }
    &bg-overlay-soft { .bg-overlay-soft; }
    &bg-overlay-dark { .bg-overlay-dark; }
});

@button-border-radius: @border-radius;
@btn-disabled-bg: @color-gray-8;
@btn-disabled-border: @color-gray-4;
@btn-disabled-text: @btn-disabled-border;

.btn {
    display: inline-block;
    padding: 0.5em 1em;
    font-weight: @font-weight-bold;
    line-height: 1.25;
    border-radius: @button-border-radius;
    text-decoration: none;
    font-family: @font-family-base;

    &:focus, &:hover {
        text-decoration: none;
    }

    &:active, &:focus {
        .tab-focus;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.2;
    }
}

.btn-loading {
    position: relative;
    color: transparent !important;
    &:after {
        position: absolute !important;
        display: block;
        height: 1rem;
        width: 1rem;
        top: 50%;
        left: 50%;
        margin-left: -0.5rem;
        margin-top: -0.5rem;
        border: 2px solid @text-dark;
        border-radius: 0.5rem;
        border-right-color: transparent;
        border-top-color: transparent;
        animation: spin-around 500ms infinite linear;
        content: "";
    }
}

.button-outline-variant(@color) {
    @inactive-border: lighten(@color, 0%);

    color: @color;
    background-color: transparent;
    border: 1px solid @inactive-border;
    font-weight: @font-weight-normal;

    &:active, &:hover {
        color: @color;
        background-color: fade(@color, 10%);
        border-color: @color;
    }

    &:focus {
        color: @color;
    }

    &:disabled {
        &:active, &:hover {
            border-color: @inactive-border;
        }
    }
}

.btn-variant(@text; @background) {
    @border-color: @background;
    @bottom-border-color: @background;
    // @bottom-border-color: darken(@background, 8%);
    @bottom-border-color-3d: darken(@background, 12%);
    @striked-color: darken(@background, 20%);

    color: @text;
    font-weight: 700;
    background-color: @background;
    border: 1px solid @border-color;
    border-bottom: 1px solid @bottom-border-color;

    s {
        color: @striked-color;
    }

    &.btn-3d {
        border-bottom: .2em solid @bottom-border-color-3d;
    }

    & when (lightness(@text) >= 50%) {
        .btn-aside {
            border-left-color: rgba(255, 255, 255, 0.3);
        }
    }
    & when (lightness(@text) < 50%) {
        .btn-aside {
            border-left-color: rgba(0, 0, 0, 0.8);
        }
    }

    &:focus {
        color: @text;
    }

    &:not(.btn-flat) {
        &:active, &:hover {
            color: lighten(@text, 5%);

            background-color: darken(@background, 5%);
            border: 1px solid darken(@border-color, 5%);
            border-bottom: 1px solid darken(@bottom-border-color, 5%);

            s {
                color: darken(@striked-color, 5%);
            }

            &.btn-3d {
                border-bottom: .2em solid darken(@bottom-border-color-3d, 5%);
            }
        }
    }

    &:disabled {
        color: @text;
        background-color: @background;
        border-color: @border-color;
        border-bottom-color: @bottom-border-color;
        opacity: 0.5;

        s {
            color: @striked-color;
        }

        &.btn-3d {
            border-bottom: .2em solid @bottom-border-color-3d;
        }
    }

    &.btn-loading {
        &:after {
            border-left-color: fade(@text, 70%);
            border-bottom-color: fade(@text, 70%);
        }
        &:disabled {
            color: @text;
            background-color: @background;
            border-color: @border-color;
            border-bottom-color: @bottom-border-color;
            opacity: 0.5;
        }
    }
}

.btn-primary {
    .btn-variant(@color-white; @color-brand-primary);
}

.btn-blue {
    .btn-variant(@color-white; @color-blue);
}

.btn-green {
    .btn-variant(@color-white; #51caaf);
}

.btn-white {
    .btn-variant(@text-brand; @color-white);
}

.btn-secondary {
    .btn-variant(@text-dark-soft; @color-gray-8);
}

.btn-primary-outline {
    .button-outline-variant(@color-brand-primary);
}

.btn-inv-outline {
    .button-outline-variant(@color-white);
}

.btn-danger {
    .button-outline-variant(@color-danger);
}

.btn-twitter {
    .btn-variant(@color-white; #26a0f0);
    font-family: "Helvetica Neue", Arial, sans-serif;
    font-weight: 500;
}

.btn-link {
    font-weight: @font-weight-normal;
    color: @text-dark;
    background-color: transparent;

    &:focus, &:hover {
        color: darken(@text-dark, 15%);
        text-decoration: underline;
    }
}

.btn-aside {
    display: inline-block;
    border-left: 1px solid rgba(0, 0, 0, 0.8);
    margin-left: 1em;
    padding-left: 1em;
}


.btn-block {
    display: block;
    width: 100%;
    text-align: center;
}

.btn-sm {
    font-size: @font-size-sm;
}

.btn-lg {
    font-size: @font-size-lg;
}

@media (min-width: (@screen-sm)) {
  .sm-btn-lg {
      .btn-lg;
  }
}

.btn-xs {
    font-size: @font-size-xs;
}

.btn-wide {
    padding-left: 2em;
    padding-right: 2em;
}

.btn-pad {
    padding: 1em 2em;
}

.btn-flat {
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
}

.btn-float {
    -webkit-font-smoothing: subpixel-antialiased; // Prevent text rendering jank on Safari
    transition: transform .4s, box-shadow .4s;
    &:hover {
        box-shadow: 0 6px 16px -4px rgba(0,0,0,0.25);
        transform: translateY(-1 * @spacer-1);
    }
}

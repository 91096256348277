.hr {
    display: block;
    box-sizing: border-box;
}

.hr-brand {
    border: none;
    background-color: @color-brand-primary;
}

.hr-thick {
    height: .375rem;
}

.hr-short {
    width: 6rem;
    margin-left: 0;
}

.icon {
    display: inline-block;
    height: 1.25em;
    width: 1.25em;
    fill: currentColor;
    vertical-align: text-bottom; // Trial and error, this is the best option for 20px icon + 16px text
}

.icon-sm {
    height: 1em;
    width: 1em;
}

//#afafaf

@z-index-1:   10;
@z-index-2:   20;
@z-index-3:   30;
@z-index-4:   40;
@z-index-5:   50;
@z-index-6:   60;
@z-index-7:   70;
@z-index-8:   80;
@z-index-9:   90;
@z-index-10: 100;

.z10  { z-index: @z-index-1; }
.z20  { z-index: @z-index-2; }
.z30  { z-index: @z-index-3; }
.z40  { z-index: @z-index-4; }
.z50  { z-index: @z-index-5; }
.z60  { z-index: @z-index-6; }
.z70  { z-index: @z-index-7; }
.z80  { z-index: @z-index-8; }
.z90  { z-index: @z-index-9; }
.z100 { z-index: @z-index-10; }

@card-border-color: @color-gray-8;
@card-border-radius: @border-radius;

.card {
    background: @color-white;
    overflow: hidden; // Prevent things breaking out of border radius
}

.card-section {
    padding: @spacer-5 @spacer-6;
}

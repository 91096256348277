.flex { display: flex; }
.flex-top { align-items: flex-start; }

.flex-col {
    display: flex;
    flex-direction: column;
    .flex-reverse {
        flex-direction: column-reverse;
    }
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-y-center {
    display: flex;
    align-items: center;
}

.flex-y-end {
    display: flex;
    align-items: flex-end;
}

.flex-y-baseline {
    display: flex;
    align-items: baseline;
}

.flex-right {
    justify-content: flex-end;
}

.flex-spaced {
    display: flex;
    justify-content: space-between;
}

.flex-around {
    display: flex;
    justify-content: space-around;
}

.flex-fill { max-width: 100%; flex: 1; }
.flex-no-shrink { flex-shrink: 0; }
.flex-inline { display: inline-flex; }

.flex-reverse { flex-direction: row-reverse; }
.flex-wrap { flex-wrap: wrap; }


.responsive({
    &flex-no-shrink { .flex-no-shrink; }
});


.text-xjumbo { font-size: @font-size-xjumbo; }
.text-jumbo { font-size: @font-size-jumbo; }
.text-xxl   { font-size: @font-size-xxl; }
.text-xl    { font-size: @font-size-xl; }
.text-lg    { font-size: @font-size-lg; }
.text-base  { font-size: @font-size-base; }
.text-sm    { font-size: @font-size-sm; }
.text-xs    { font-size: @font-size-xs; }

.h1 { font-size: @font-size-h1; }
.h2 { font-size: @font-size-h2; }
.h3 { font-size: @font-size-h3; }
.h4 { font-size: @font-size-h4; }

.text-light         { color: @text-light; }
.text-light-soft    { color: @text-light-soft; }
.text-light-muted   { color: @text-light-muted; }
.text-light-faint   { color: @text-light-faint; }
.text-dark          { color: @text-dark; }
.text-dark-soft     { color: @text-dark-soft; }
.text-dark-muted    { color: @text-dark-muted; }
.text-dark-faint    { color: @text-dark-faint; }

.text-danger    { color: @text-danger; }

.text-em { font-style: italic; }
.text-underline { text-decoration: underline; }

.wt-thin   { font-weight: @font-weight-thin; }
.wt-light   { font-weight: @font-weight-light; }
.wt-normal  { font-weight: @font-weight-normal; }
.wt-medium  { font-weight: @font-weight-medium; }
.wt-bold    { font-weight: @font-weight-bold; }

.lh-tight { line-height: 1.25; }

.text-center { text-align: center; }
.text-left { text-align: left; }
.text-right { text-align: right; }

.text-sm-left {
    @media (min-width: @screen-sm) {
        text-align: left;
    }
}

.text-uppercase { text-transform: uppercase; }

.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-break { white-space: pre-line; }

.text-smooth { -webkit-font-smoothing: antialiased; }

.text-spaced { letter-spacing: 0.05em; }

.text-strike { text-decoration: line-through; }

.text-shadow-solid { text-shadow: 0 2px 0 rgba(0,0,0,0.15); }

a, .link {
    font-weight: @font-weight-normal;
    color: @text-dark;
    text-decoration: none;

    &:hover, &:focus {
        color: darken(@text-dark, 15%);
        text-decoration: underline;
    }
    &:focus {
        .tab-focus;
    }
}

.link-brand {
    color: @text-brand;

    &:hover, &:focus {
        color: darken(@text-brand, 15%);
    }
}

.link-underline {
    text-decoration: underline;
}

.link-plain {
    &:hover, &:focus {
        text-decoration: none;
    }
}

.link-soft {
    .text-dark-soft;

    &:hover, &:focus {
        .text-dark;
    }
}

.link-muted {
    .text-dark-muted;

    &:hover, &:focus {
        .text-dark;
    }
}

.link-faint {
    .text-dark-faint;

    &:hover, &:focus {
        .text-dark;
    }
}

.link-light {
    color: @text-light;

    &:hover, &:focus {
        color: @text-light;
    }
}

.link-light-soft {
    color: @text-light-soft;

    &:hover, &:focus {
        color: @text-light;
    }
}

.link-light-muted {
    color: @text-light-muted;

    &:hover, &:focus {
        color: @text-light-soft;
    }
}



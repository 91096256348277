.navbar {
    padding-top: @spacer-4;
    padding-bottom: @spacer-4;
}

.navbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@system-font-stack: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif;
@font-family-base: @system-font-stack;
// @font-family-base: "Open Sans", @system-font-stack;
@font-family-base: "Lato", @system-font-stack;
// @font-family-base: "Proxima Nova", @system-font-stack;
@line-height: 1.5;

@font-size-xjumbo: (45rem / 18rem);     // 45px
@font-size-jumbo: (36rem / 16rem);      // 36px
@font-size-xxl: (30rem / 16rem);    // 30px
@font-size-xl: (22rem / 16rem);     // 22px
@font-size-lg: (18rem / 16rem);      // 18px
@font-size-base: 1rem;                  // 16px
@font-size-sm: (14rem / 16rem);      // 14px
@font-size-xs: (12rem / 16rem);       // 12px

@font-size-h1: @font-size-xxl;
@font-size-h2: @font-size-xl;
@font-size-h3: @font-size-lg;
@font-size-h4: @font-size-base;

@font-weight-thin: 200;
@font-weight-light: 300;
@font-weight-normal: 400;
@font-weight-medium: 600;
@font-weight-bold: 700;

@text-light: rgb(255, 255, 255);
@text-light-soft: fade(@text-light, 90%);
@text-light-muted: fade(@text-light, 60%);
@text-light-faint: fade(@text-light, 35%);
@text-dark: hsl(@hue-brand-primary, 10%, 25%);
@text-dark-soft: hsl(@hue-brand-primary, 10%, 40%);
@text-dark-muted: hsl(@hue-brand-primary, 10%, 60%);
@text-dark-faint: hsl(@hue-brand-primary, 10%, 80%);
@text-brand: hsl(@hue-brand-primary, 100%, 40%);
@text-danger: @color-danger;


small {
    font-size: @font-size-sm;
}

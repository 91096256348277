@progress-bg: @color-gray-8;
@progress-bar-color: #79bc70;
@progress-border-radius: @border-radius;

.progress {
  display: block;
  width: 100%;
  height: @spacer-2;
}

.progress[value] {
  // Set overall background
  background-color: @progress-bg;
  // Remove Firefox and Opera border
  border: 0;
  // Reset the default appearance
  appearance: none;
  // Set overall border radius
  border-radius: @progress-border-radius;
}

// Filled-in portion of the bar
.progress[value]::-ms-fill {
  background-color: @progress-bar-color;
  // Remove right-hand border of value bar from IE10+/Edge
  border: 0;
}
.progress[value]::-moz-progress-bar {
  background-color: @progress-bar-color;
  border-top-left-radius: @progress-border-radius;
  border-bottom-left-radius: @progress-border-radius;
}
.progress[value]::-webkit-progress-value {
  background-color: @progress-bar-color;
  border-top-left-radius: @progress-border-radius;
  border-bottom-left-radius: @progress-border-radius;
}
// Tweaks for full progress bar
.progress[value="100"]::-moz-progress-bar {
  border-top-right-radius: @progress-border-radius;
  border-bottom-right-radius: @progress-border-radius;
}
.progress[value="100"]::-webkit-progress-value {
  border-top-right-radius: @progress-border-radius;
  border-bottom-right-radius: @progress-border-radius;
}

// Unfilled portion of the bar
.progress[value]::-webkit-progress-bar {
  background-color: @progress-bg;
  border-radius: @progress-border-radius;
}

// Absurd-but-syntactically-valid selector to make these styles Firefox-only
base::-moz-progress-bar,
.progress[value] {
  background-color: @progress-bg;
  border-radius: @progress-border-radius;
}

.progress-variant (@color) {
  &[value]::-webkit-progress-value {
    background-color: @color;
  }

  &[value]::-moz-progress-bar {
    background-color: @color;
  }

  // IE10+, Microsoft Edge
  &[value]::-ms-fill {
    background-color: @color;
  }
}

.bullet-list {
    list-style-type: disc;
    padding-left: 2.5rem;
    > li {
        margin-bottom: @spacer-2;
    }
}

.numbered-list {
    list-style-type: decimal;
    padding-left: 2rem;
    > li {
        margin-bottom: @spacer-2;
    }
}

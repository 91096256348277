// Use Bootstrap 4 breakpoints as a starting point
@screen-xs: 420px; // Not used for grid, just changing font size for tiny fucking iPhones
@screen-sm: 544px;
@screen-md: 768px;
@screen-lg: 992px;
@screen-xl: 992px;
// @screen-xl: 1200px;

@container-width-sm: 576px;
@container-width-md: 720px;
@container-width-lg: 940px;
@container-width-xl: 940px;
// @container-width-xl: 1140px;

// From flexboxgrid.com
@gutter-width: 2rem;
@half-gutter-width: @gutter-width * 0.5;
@gutter-compensation: @half-gutter-width * -1;
@outer-margin: @half-gutter-width;
@sm-min: @screen-sm;
@md-min: @screen-md;
@lg-min: @screen-lg;
@xl-min: @screen-xl;
@screen-sm-min: @sm-min;
@screen-md-min: @md-min;
@screen-lg-min: @lg-min;
@screen-xl-min: @xl-min;
@container-sm: @container-width-sm;
@container-md: @container-width-md;
@container-lg: @container-width-lg;
@container-xl: @container-width-xl;

.container-fluid, .container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: @outer-margin;
  padding-left: @outer-margin;
}

.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: @gutter-compensation;
  margin-left: @gutter-compensation;
}

.row.reverse {
  flex-direction: row-reverse;
}

.col.reverse {
  flex-direction: column-reverse;
}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.offset-0,
.offset-1,
.offset-2,
.offset-3,
.offset-4,
.offset-5,
.offset-6,
.offset-7,
.offset-8,
.offset-9,
.offset-10,
.offset-11,
.offset-12 {
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: @half-gutter-width;
  padding-left: @half-gutter-width;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-1 {
  flex-basis: 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex-basis: 25%;
  max-width: 25%;
}

.col-4 {
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex-basis: 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex-basis: 50%;
  max-width: 50%;
}

.col-7 {
  flex-basis: 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex-basis: 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex-basis: 75%;
  max-width: 75%;
}

.col-10 {
  flex-basis: 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex-basis: 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex-basis: 100%;
  max-width: 100%;
}

.offset-0 {
  margin-left: 0;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

// These need to be renamed before being brought back...

// .start-xs {
//   justify-content: flex-start;
//   text-align: start;
// }

// .center-xs {
//   justify-content: center;
//   text-align: center;
// }

// .end-xs {
//   justify-content: flex-end;
//   text-align: end;
// }

// .top-xs {
//   align-items: flex-start;
// }

// .middle-xs {
//   align-items: center;
// }

// .bottom-xs {
//   align-items: flex-end;
// }

// .around-xs {
//   justify-content: space-around;
// }

// .between-xs {
//   justify-content: space-between;
// }

.xs-col-first {
  order: -1;
}

.xs-col-last {
  order: 1;
}

@media only screen and (min-width: @screen-sm-min) {
  .container {
    max-width: @container-sm;
  }

  .sm-col,
  .sm-col-1,
  .sm-col-2,
  .sm-col-3,
  .sm-col-4,
  .sm-col-5,
  .sm-col-6,
  .sm-col-7,
  .sm-col-8,
  .sm-col-9,
  .sm-col-10,
  .sm-col-11,
  .sm-col-12,
  .sm-offset-0,
  .sm-offset-1,
  .sm-offset-2,
  .sm-offset-3,
  .sm-offset-4,
  .sm-offset-5,
  .sm-offset-6,
  .sm-offset-7,
  .sm-offset-8,
  .sm-offset-9,
  .sm-offset-10,
  .sm-offset-11,
  .sm-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: @half-gutter-width;
    padding-left: @half-gutter-width;
  }

  .sm-col {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .sm-col-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .sm-col-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .sm-col-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .sm-col-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .sm-col-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .sm-col-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .sm-col-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .sm-col-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .sm-col-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .sm-col-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .sm-col-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .sm-col-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .sm-offset-0 {
    margin-left: 0;
  }

  .sm-offset-1 {
    margin-left: 8.33333333%;
  }

  .sm-offset-2 {
    margin-left: 16.66666667%;
  }

  .sm-offset-3 {
    margin-left: 25%;
  }

  .sm-offset-4 {
    margin-left: 33.33333333%;
  }

  .sm-offset-5 {
    margin-left: 41.66666667%;
  }

  .sm-offset-6 {
    margin-left: 50%;
  }

  .sm-offset-7 {
    margin-left: 58.33333333%;
  }

  .sm-offset-8 {
    margin-left: 66.66666667%;
  }

  .sm-offset-9 {
    margin-left: 75%;
  }

  .sm-offset-10 {
    margin-left: 83.33333333%;
  }

  .sm-offset-11 {
    margin-left: 91.66666667%;
  }

  // .start-sm {
  //   justify-content: flex-start;
  //   text-align: start;
  // }

  // .center-sm {
  //   justify-content: center;
  //   text-align: center;
  // }

  // .end-sm {
  //   justify-content: flex-end;
  //   text-align: end;
  // }

  // .top-sm {
  //   align-items: flex-start;
  // }

  // .middle-sm {
  //   align-items: center;
  // }

  // .bottom-sm {
  //   align-items: flex-end;
  // }

  // .around-sm {
  //   justify-content: space-around;
  // }

  // .between-sm {
  //   justify-content: space-between;
  // }

  .sm-col-first {
    order: -1;
  }

  .sm-col-last {
    order: 1;
  }
}

@media only screen and (min-width: @screen-md-min) {
  .container {
    max-width: @container-md;
  }

  .md-col,
  .md-col-1,
  .md-col-2,
  .md-col-3,
  .md-col-4,
  .md-col-5,
  .md-col-6,
  .md-col-7,
  .md-col-8,
  .md-col-9,
  .md-col-10,
  .md-col-11,
  .md-col-12,
  .md-offset-0,
  .md-offset-1,
  .md-offset-2,
  .md-offset-3,
  .md-offset-4,
  .md-offset-5,
  .md-offset-6,
  .md-offset-7,
  .md-offset-8,
  .md-offset-9,
  .md-offset-10,
  .md-offset-11,
  .md-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: @half-gutter-width;
    padding-left: @half-gutter-width;
  }

  .md-col {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .md-col-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .md-col-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .md-col-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .md-col-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .md-col-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .md-col-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .md-col-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .md-col-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .md-col-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .md-col-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .md-col-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .md-col-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .md-offset-0 {
    margin-left: 0;
  }

  .md-offset-1 {
    margin-left: 8.33333333%;
  }

  .md-offset-2 {
    margin-left: 16.66666667%;
  }

  .md-offset-3 {
    margin-left: 25%;
  }

  .md-offset-4 {
    margin-left: 33.33333333%;
  }

  .md-offset-5 {
    margin-left: 41.66666667%;
  }

  .md-offset-6 {
    margin-left: 50%;
  }

  .md-offset-7 {
    margin-left: 58.33333333%;
  }

  .md-offset-8 {
    margin-left: 66.66666667%;
  }

  .md-offset-9 {
    margin-left: 75%;
  }

  .md-offset-10 {
    margin-left: 83.33333333%;
  }

  .md-offset-11 {
    margin-left: 91.66666667%;
  }

  // .start-md {
  //   justify-content: flex-start;
  //   text-align: start;
  // }

  // .center-md {
  //   justify-content: center;
  //   text-align: center;
  // }

  // .end-md {
  //   justify-content: flex-end;
  //   text-align: end;
  // }

  // .top-md {
  //   align-items: flex-start;
  // }

  // .middle-md {
  //   align-items: center;
  // }

  // .bottom-md {
  //   align-items: flex-end;
  // }

  // .around-md {
  //   justify-content: space-around;
  // }

  // .between-md {
  //   justify-content: space-between;
  // }

  .md-col-first {
    order: -1;
  }

  .md-col-last {
    order: 1;
  }
}

@media only screen and (min-width: @screen-lg-min) {
  .container {
    max-width: @container-lg;
  }

  .lg-col,
  .lg-col-1,
  .lg-col-2,
  .lg-col-3,
  .lg-col-4,
  .lg-col-5,
  .lg-col-6,
  .lg-col-7,
  .lg-col-8,
  .lg-col-9,
  .lg-col-10,
  .lg-col-11,
  .lg-col-12,
  .lg-offset-0,
  .lg-offset-1,
  .lg-offset-2,
  .lg-offset-3,
  .lg-offset-4,
  .lg-offset-5,
  .lg-offset-6,
  .lg-offset-7,
  .lg-offset-8,
  .lg-offset-9,
  .lg-offset-10,
  .lg-offset-11,
  .lg-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: @half-gutter-width;
    padding-left: @half-gutter-width;
  }

  .lg-col {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .lg-col-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .lg-col-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .lg-col-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .lg-col-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .lg-col-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .lg-col-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .lg-col-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .lg-col-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .lg-col-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .lg-col-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .lg-col-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .lg-col-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .lg-offset-0 {
    margin-left: 0;
  }

  .lg-offset-1 {
    margin-left: 8.33333333%;
  }

  .lg-offset-2 {
    margin-left: 16.66666667%;
  }

  .lg-offset-3 {
    margin-left: 25%;
  }

  .lg-offset-4 {
    margin-left: 33.33333333%;
  }

  .lg-offset-5 {
    margin-left: 41.66666667%;
  }

  .lg-offset-6 {
    margin-left: 50%;
  }

  .lg-offset-7 {
    margin-left: 58.33333333%;
  }

  .lg-offset-8 {
    margin-left: 66.66666667%;
  }

  .lg-offset-9 {
    margin-left: 75%;
  }

  .lg-offset-10 {
    margin-left: 83.33333333%;
  }

  .lg-offset-11 {
    margin-left: 91.66666667%;
  }

  // .start-lg {
  //   justify-content: flex-start;
  //   text-align: start;
  // }

  // .center-lg {
  //   justify-content: center;
  //   text-align: center;
  // }

  // .end-lg {
  //   justify-content: flex-end;
  //   text-align: end;
  // }

  // .top-lg {
  //   align-items: flex-start;
  // }

  // .middle-lg {
  //   align-items: center;
  // }

  // .bottom-lg {
  //   align-items: flex-end;
  // }

  // .around-lg {
  //   justify-content: space-around;
  // }

  // .between-lg {
  //   justify-content: space-between;
  // }

  .lg-col-first {
    order: -1;
  }

  .lg-col-last {
    order: 1;
  }
}


@media only screen and (min-width: @screen-xl-min) {
  .container {
    max-width: @container-xl;
  }

  .xl-col,
  .xl-col-1,
  .xl-col-2,
  .xl-col-3,
  .xl-col-4,
  .xl-col-5,
  .xl-col-6,
  .xl-col-7,
  .xl-col-8,
  .xl-col-9,
  .xl-col-10,
  .xl-col-11,
  .xl-col-12,
  .xl-offset-0,
  .xl-offset-1,
  .xl-offset-2,
  .xl-offset-3,
  .xl-offset-4,
  .xl-offset-5,
  .xl-offset-6,
  .xl-offset-7,
  .xl-offset-8,
  .xl-offset-9,
  .xl-offset-10,
  .xl-offset-11,
  .xl-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: @half-gutter-width;
    padding-left: @half-gutter-width;
  }

  .xl-col {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .xl-col-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .xl-col-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .xl-col-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .xl-col-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .xl-col-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .xl-col-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .xl-col-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .xl-col-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .xl-col-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .xl-col-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .xl-col-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .xl-col-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .xl-offset-0 {
    margin-left: 0;
  }

  .xl-offset-1 {
    margin-left: 8.33333333%;
  }

  .xl-offset-2 {
    margin-left: 16.66666667%;
  }

  .xl-offset-3 {
    margin-left: 25%;
  }

  .xl-offset-4 {
    margin-left: 33.33333333%;
  }

  .xl-offset-5 {
    margin-left: 41.66666667%;
  }

  .xl-offset-6 {
    margin-left: 50%;
  }

  .xl-offset-7 {
    margin-left: 58.33333333%;
  }

  .xl-offset-8 {
    margin-left: 66.66666667%;
  }

  .xl-offset-9 {
    margin-left: 75%;
  }

  .xl-offset-10 {
    margin-left: 83.33333333%;
  }

  .xl-offset-11 {
    margin-left: 91.66666667%;
  }

  // .start-xl {
  //   justify-content: flex-start;
  //   text-align: start;
  // }

  // .center-xl {
  //   justify-content: center;
  //   text-align: center;
  // }

  // .end-xl {
  //   justify-content: flex-end;
  //   text-align: end;
  // }

  // .top-xl {
  //   align-items: flex-start;
  // }

  // .middle-xl {
  //   align-items: center;
  // }

  // .bottom-xl {
  //   align-items: flex-end;
  // }

  // .around-xl {
  //   justify-content: space-around;
  // }

  // .between-xl {
  //   justify-content: space-between;
  // }

  .xl-col-first {
    order: -1;
  }

  .xl-col-last {
    order: 1;
  }
}


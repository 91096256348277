@import "typography/links";
@import "typography/utilities";

.screen-sm(@ruleset) { @media (min-width: @screen-sm) { @ruleset(); }}
.screen-md(@ruleset) { @media (min-width: @screen-md) { @ruleset(); }}
.screen-lg(@ruleset) { @media (min-width: @screen-lg) { @ruleset(); }}
.screen-xl(@ruleset) { @media (min-width: @screen-xl) { @ruleset(); }}

.responsive(@ruleset) {
    .screen-sm({ .sm- { @ruleset(); }});
    .screen-md({ .md- { @ruleset(); }});
    .screen-lg({ .lg- { @ruleset(); }});
    .screen-xl({ .xl- { @ruleset(); }});
}

.no-focus:focus {
  outline: none;
}

.no-wrap {
  white-space: nowrap;
}

.box-shadow {
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.box-shadow-soft {
    box-shadow: 0 2px 4px rgba(0,0,0,0.02);
}

.box-shadow-z2 {
    box-shadow: 0 15px 35px hsla(240, 10%, 28%, 0.15), 0 5px 15px rgba(0,0,0,.07);
}

.responsive({
    &box-shadow-z2 { .box-shadow-z2; }
});

.inset-shadow {
  box-shadow: inset 0 1px 2px rgba(0,0,0,0.05);
}

.tab-focus {
  // Default
  outline: thin dotted;
  // WebKit
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.full-height {
    min-height: 100vh;
}

.full-width {
    width: 100%;
}

.auto-width {
    width: auto;
}

.responsive({
    &full-width { .full-width; }
    &auto-width { .auto-width; }
});

.is-clickable {
    cursor: pointer;
}

.no-select {
  user-select: none;
}

.ease {
  transition: all 0.2s ease;
}

// Useful for hiding things like radio buttons without making them inaccessible
// to the keyboard when navigating forms.
.pseudo-hidden {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

// Display
.block { display: block; }
.inline-block { display: inline-block; }
.flex { display: flex; }
.hidden { display: none; }

.responsive({
    &block { .block; }
    &inline-block { .inline-block; }
    &flex { .flex; }
    &hidden { .hidden; }
});

// Position
.fixed { position: fixed; }
.absolute { position: absolute; }
.relative { position: relative; }

.pin-t { top: 0; }
.pin-r { right: 0; }
.pin-b { bottom: 0; }
.pin-l { left: 0; }
.pin-y { .pin-t; .pin-b; }
.pin-x { .pin-l; .pin-r; }
.pin { .pin-y; .pin-x; width: 100%; height: 100%; }

.hide-overflow { overflow: hidden; }

.aspect-2\:1 {
    padding-bottom: 50%;
}

.aspect-16\:9 {
    padding-bottom: 56.25%;
}

.hover-only {
  opacity: 0;
  &:hover {
    opacity: 1;
  }
}

.responsive({
    &hover-only { .hover-only; }
});

.bg-cover {
    background-size: cover;
    background-position: center;
}

.media-object {
    display: flex;
}

.media-left {
    > .zondicon {
        vertical-align: sub; // Trial and error bullshit
    }
}

.media-body {
    flex: 1;
}

@spacer-0: 0;
@spacer-1: 0.25rem;
@spacer-2: 0.5rem;
@spacer-3: 0.75rem;
@spacer-4: 1rem;
@spacer-5: 1.5rem;
@spacer-6: 2rem;
@spacer-7: 3rem;
@spacer-8: 4rem;
@spacer-9: 5rem;
@spacer-10: 6rem;
@spacer-11: 8rem;
@spacer-12: 10rem;

@spacers: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12;

.generate-padding-utilities(@prefix; @i: 1) when (@i <= length(@spacers)) {
    @n: extract(@spacers, @i);

    .@{prefix}p-t-@{n} { padding-top: ~"@{spacer-@{n}}"; }
    .@{prefix}p-r-@{n} { padding-right: ~"@{spacer-@{n}}"; }
    .@{prefix}p-b-@{n} { padding-bottom: ~"@{spacer-@{n}}"; }
    .@{prefix}p-l-@{n} { padding-left: ~"@{spacer-@{n}}"; }
    .@{prefix}p-y-@{n} {
        padding-top: ~"@{spacer-@{n}}";
        padding-bottom: ~"@{spacer-@{n}}";
    }
    .@{prefix}p-x-@{n} {
        padding-right: ~"@{spacer-@{n}}";
        padding-left: ~"@{spacer-@{n}}";
    }
    .@{prefix}p-@{n} {
        padding-top: ~"@{spacer-@{n}}";
        padding-bottom: ~"@{spacer-@{n}}";
        padding-right: ~"@{spacer-@{n}}";
        padding-left: ~"@{spacer-@{n}}";
    }

    .generate-padding-utilities(@prefix; (@i + 1));
}

.generate-margin-utilities(@prefix; @i: 1) when (@i <= length(@spacers)) {
    @n: extract(@spacers, @i);

    // Negative top margins, for pulling content up
    .@{prefix}m-t-n@{n} { margin-top: ~"-@{spacer-@{n}}"; }

    // Negative left margins, for pulling content up
    .@{prefix}m-l-n@{n} { margin-left: ~"-@{spacer-@{n}}"; }

    .@{prefix}m-t-@{n} { margin-top: ~"@{spacer-@{n}}"; }
    .@{prefix}m-r-@{n} { margin-right: ~"@{spacer-@{n}}"; }
    .@{prefix}m-b-@{n} { margin-bottom: ~"@{spacer-@{n}}"; }
    .@{prefix}m-l-@{n} { margin-left: ~"@{spacer-@{n}}"; }
    .@{prefix}m-y-@{n} {
        margin-top: ~"@{spacer-@{n}}";
        margin-bottom: ~"@{spacer-@{n}}";
    }
    .@{prefix}m-x-@{n} {
        margin-right: ~"@{spacer-@{n}}";
        margin-left: ~"@{spacer-@{n}}";
    }
    .@{prefix}m-@{n} {
        margin-top: ~"@{spacer-@{n}}";
        margin-bottom: ~"@{spacer-@{n}}";
        margin-right: ~"@{spacer-@{n}}";
        margin-left: ~"@{spacer-@{n}}";
    }

    .generate-margin-utilities(@prefix, (@i + 1));
}

.generate-padding-utilities(~"");
.generate-margin-utilities(~"");
.m-x-auto { margin-left: auto; margin-right: auto; }

.screen-sm({
    .generate-padding-utilities(~"sm-");
    .generate-margin-utilities(~"sm-");
    .sm-m-x-auto { margin-left: auto; margin-right: auto; }
});

.screen-md({
    .generate-padding-utilities(~"md-");
    .generate-margin-utilities(~"md-");
    .md-m-x-auto { margin-left: auto; margin-right: auto; }
});

.screen-lg({
    .generate-padding-utilities(~"lg-");
    .generate-margin-utilities(~"lg-");
    .lg-m-x-auto { margin-left: auto; margin-right: auto; }
});

.screen-xl({
    .generate-padding-utilities(~"xl-");
    .generate-margin-utilities(~"xl-");
    .xl-m-x-auto { margin-left: auto; margin-right: auto; }
});

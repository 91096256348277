@border-light-color: @color-gray-8;
@border-color: @color-gray-7;
@border-dark-color: @color-gray-6;
@border-radius: 0.25rem;
@border-radius: 0;
@border-radius-lg: 0.5rem;

@border-1: 1px;
@border-2: 2px;

.border { border: 1px solid @border-color; }

.border-t { border-top: @border-1 solid @border-color; }
.border-b { border-bottom: @border-1 solid @border-color; }
.border-l { border-left: @border-1 solid @border-color; }
.border-r { border-right: @border-1 solid @border-color; }

.border-rounded { border-radius: @border-radius; }
.border-rounded-lg { border-radius: @border-radius-lg; }

.border-l-2 { border-left: @border-2 solid @border-color; }
.border-l-0 { border-left: none; }

.border-brand { border-color: @color-brand-primary; }
.border-dark { border-color: @border-dark-color; }
.border-light { border-color: @border-light-color; }

.border-t-l-0 { border-top-left-radius: 0; }
.border-t-r-0 { border-top-right-radius: 0; }
.border-b-l-0 { border-bottom-left-radius: 0; }
.border-b-r-0 { border-bottom-right-radius: 0; }

.border-pull-b { margin-bottom: (-1 * @border-1); }

.border-medium { border-width: 4px; }
.border-thick { border-width: 8px; }

.responsive({
    &border-rounded { .border-rounded; }
    &border-rounded-lg { .border-rounded-lg; }
});

.markdown {
  h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
    color: @text-dark-soft;
    margin-bottom: 1rem;
  }

  h1 {
    font-size: @font-size-xl;
  }

  h2 {
    font-size:  @font-size-lg;
    font-weight: @font-weight-normal;
    color: @text-dark-soft;
  }

  h3 {
    font-size: @font-size-base;
    font-weight: @font-weight-medium;
    color: @text-dark-soft;
  }

  p {
    font-size: @font-size-base;
    line-height: 1.5;
    margin-bottom: 1.5em;
    color: @text-dark;
  }

  blockquote {
    background-color: @bg-muted;
    padding: 1.5rem;
    border-radius: 4px;
    margin-bottom: 2em;
  }

  blockquote > p {
    font-size: @font-size-base;
    margin-bottom: 0;
    color: @text-dark;
  }

  blockquote p code {
    background-color: #e5e5e5;
  }

  ul, ol {
    margin: 0 0 2em;
  }

  ul {
    list-style: disc inside;
  }

  ol {
    list-style: decimal inside;
  }

  li {
    font-size: @font-size-base;
    line-height: 2;
    color: @text-dark;
  }

  a {
    .link-brand;
  }

  pre {
    margin-top: 2em;
    margin-bottom: 2em;
    overflow: auto;
    padding: 1.5rem;
    border: 1px solid @bg-muted;
    border-radius: 4px;
    max-width: 100%;
    background-color: @bg-muted;
  }

  pre > code {
    font-family: monospace;
    font-size: 14px;
    background-color: transparent;
    line-height: 1.8;
    word-break: normal;
    word-spacing: normal;
    white-space: pre;
    hyphens: none;
    direction: ltr;
    tab-size: 4;
  }

  code {
    background-color: @bg-muted;
    padding: 0.6rem 0.5rem;
    font-size: 13px;
    border-radius: 3px;
  }
}

img {
    vertical-align: middle;
}

.img-fit {
    max-width: 100%;
}

.img-inline {
    height: 2em;
    width: auto;
}

.img-circle { border-radius: 50%; }

.img-md {
    height: 4rem;
    width: auto;
}

.img-100 {
    max-width: 100px;
}

.sm-img-fit {
    @media (min-width: @screen-sm) {
        max-width: 100%;
    }
}

.img-7r {
    height: auto;
    width: 100%;
    max-width: 7rem;
}

.img-12r {
    height: auto;
    width: 100%;
    max-width: 12rem;
}

.img-16r {
    height: auto;
    width: 100%;
    max-width: 16rem;
}

.img-24r {
    height: auto;
    width: 100%;
    max-width: 24rem;
}

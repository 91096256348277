.slide-down-enter-active, .slide-down-leave-active {
  transition: all .5s;
}
.slide-down-enter, .slide-down-leave-active {
  transform: translateY(-1.5em);
  opacity: 0;
}

.fade-grow-enter, .fade-grow-leave-active {
    transform: scale(.7);
    opacity: 0;
}

.fade-grow-enter-active, .fade-grow-leave {
    transition: transform .4s cubic-bezier(.075,.82,.165,1), opacity .35s cubic-bezier(.075,.82,.165,1);
}

.fade-enter, .fade-leave-active {
    opacity: 0;
}

.fade-enter-active, .fade-leave {
    transition: opacity .3s;
}

.fade-slow-enter, .fade-slow-leave-active {
    opacity: 0;
}

.fade-slow-enter-active, .fade-slow-leave {
    transition: opacity .5s;
}
